<template>
  <div class="GroupDetail">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn">
          <el-upload
            :action="urls.refundAliPayOrder"
            name="file"
            :headers="{
              Authorization: stores.state.token,
            }"
            :data="{ competitionId: this.competition }"
            :multiple="false"
            :show-file-list="false"
            :on-success="uploadZfb"
            :on-error="uploadZfb"
            list-type="文件类型列表"
          >
            <el-button
              style="height: 40px; margin-right: 10px"
              type="primary"
              plain
              >退还支付宝费用</el-button
            >
          </el-upload>
          <el-upload
            :action="urls.uploadWeChatRefundExcel"
            name="file"
            :headers="{
              Authorization: stores.state.token,
            }"
            :data="{ competitionId: this.competition }"
            :multiple="false"
            :show-file-list="false"
            :on-success="uploadVx"
            :on-error="uploadVx"
            list-type="文件类型列表"
          >
            <el-button
              style="height: 40px; margin-right: 10px"
              type="primary"
              plain
              >上传微信退费记录</el-button
            >
          </el-upload>
          <!-- <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="handleExport"
            >导出组别</el-button
          > -->
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="exportTable"
            >支付宝退费模板下载</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="文件名" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="类型" width="200">
            <template slot-scope="scope">
              <span>{{ typeList[scope.row.type] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款成功数" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.successCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款失败数" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.failCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款时间" width="300">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row.id, scope.row)"
                >下载</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import group from "../../api/group";
import store from "../../store";
export default {
  data() {
    return {
      totalCount: 0,
      urls: group.URLs,
      typeList: {
        1: "微信",
        2: "支付宝",
      },
      stores: store,
      isUp: false,
      competition: this.$route.params.id,
      id: this.$route.params.id,
      groupCode: "",
      name: "",
      groupCodeName: "",
      fileList: [],
      labelPosition: "right",
      value: "所有",
      value2: true,
      tableData: [],
      currentPage: +this.$route.params.currentPage,
      pageSize: +this.$route.params.pageSize,
      total: 0,
      dialogFormEditVisible: false,
      dialogFormAddVisible: false,
      codeName: "",
      editForm: {},
      addForm: {
        showRegister: false,
        showGrade: false,
      },
    };
  },
  created() {
    this.getTabeleData();
  },
  methods: {
    //下载模板
    exportTable() {
      window.location.href =
        "http://asset.idance5.com/competition/template/refund_template.xls";
    },
    //上传微信时的回调处理
    uploadVx(callBackData) {
      const { status_code, msg } = callBackData;
      console.log(callBackData);
      if (status_code === "200") {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.getTabeleData();
      } else if (status_code == "1002") {
        this.$message({
          type: "warning",
          message: "文件格式不正确",
        });
      }
    },
    //上传支付宝时的回调处理
    uploadZfb(callBackData) {
      const { status_code, msg } = callBackData;
      console.log(callBackData);
      if (status_code === "200") {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.getTabeleData();
      } else if (status_code == "1002") {
        this.$message({
          type: "warning",
          message: "文件格式不正确",
        });
      }
    },
    //搜索组别
    async getTabeleData() {
      try {
        const result = await this.$api.findRefundExcelList(
          `currentPage=${this.$route.params.currentPage}&pageSize=${this.$route.params.pageSize}&search_EQ_competitionId=${this.id}&search_EQ_type=`
        );
        this.tableData = result.pageResults;
        console.log(result);
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //导出组别
    async handleExport(index, row) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportGroup(`competitionId=${this.id}`);
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //下载
    handleDelete(id, row) {
      console.log(row.url, "111");
      window.location.href = row.url;
    },
    //每页显示条数改变
    handleSizeChange(val) {
      this.$router.replace({
        name: "SportsDetail",
        params: {
          back: this.$route.params.back,
          id: this.$route.params.id,
          name: this.$route.params.name,
          menu: this.$route.params.menu,
          pageSize: val,
          currentPage: +this.$route.params.currentPage,
        },
      });
      this.getTabeleData();
    },
    //当前页跳转
    handleCurrentChange(val) {
      this.$router.replace({
        name: "SportsDetail",
        params: {
          back: this.$route.params.back,
          id: this.$route.params.id,
          name: this.$route.params.name,
          menu: this.$route.params.menu,
          pageSize: +this.$route.params.pageSize,
          currentPage: val,
        },
      });
      this.getTabeleData();
    },
  },
};
</script>

<style lang="less">
.GroupDetail {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .main-card {
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .group {
    .el-dialog {
      min-height: 1136px;
      min-width: 1713px;
    }
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
  .national {
    width: 65px;
  }
  .select {
    .el-input {
      width: 93%;
    }
  }
}
</style>
